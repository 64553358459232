import * as Styled from './styled';

const Row: React.FC<
  React.HTMLAttributes<HTMLDivElement> & {
    children?: React.ReactNode;
    gap?: number;
    alignItems?: React.CSSProperties['alignItems'];
    justifyContent?: React.CSSProperties['justifyContent'];
    theme?: any;
    style?: React.CSSProperties;
    className?: string;
  }
> = ({
  children,
  gap = 100,
  alignItems = 'flex-start',
  justifyContent = 'flex-start',
  theme = {},
  className,
  style,
  ...props
}) => {
  return (
    <Styled.Row
      // className={`${styles.row} ${className}`}
      className={className}
      style={{ gap: gap, alignItems: alignItems, justifyContent: justifyContent, ...style }}
      {...props}
    >
      {children}
    </Styled.Row>
  );
};

export default Row;
