'use client';
import React from 'react';
import styled, { css } from 'styled-components';
import { colors, toRgba, darken, lighten, breakPoints, getShades } from '@/styles';

const buttonHoverShadow = css`
  box-shadow: 2px 2px 7px ${toRgba(colors.primary.gray, 0.05)};
  transition: box-shadow 0.1s ease-in-out;
`;

const primaryGradient = `
  linear-gradient(120deg, #6e40c9 0%, ${darken('#6e40c9', 0.25)} 100%), #6e40c9;
`;

const primaryGradientHover = `
  linear-gradient(120deg, ${getShades('#6e40c9')[400]} 0%, ${getShades(darken('#6e40c9', 0.25))[400]} 100%), ${getShades('#6e40c9')[400]};
`;

const buttonPadding = (props: any) => {
  if (props.$size == 'small') return '8px 15px';
  if (props.$size == 'smaller') return '5px 10px';
  if (props.$size == 'inline') return '0 10px';
  if (props.$size == 'tiny') return '3px 8px';
  return '15px 30px';
};

const buttonFontSize = (props: any) => {
  if (props.$size == 'small') return '14px';
  if (props.$size == 'inline') return '14px';
  if (props.$size == 'smaller') return '12px';
  if (props.$size == 'tiny') return '14px';
  return '16px';
};

const buttonGap = (props: any) => {
  if (props.$size == 'small') return '10px';
  if (props.$size == 'inline') return '10px';
  if (props.$size == 'smaller') return '5px';
  if (props.$size == 'tiny') return '5px';
  return '10px';
};

const iconSize = (props: any) => {
  if (props.$size == 'small') return '14px';
  if (props.$size == 'inline') return '14px';
  if (props.$size == 'smaller') return '12px';
  if (props.$size == 'tiny') return '12px';
  return '16px';
};

const ButtonAs = ({ as, ...props }: { as?: string; [key: string]: any }) => {
  if (as === 'a') return React.createElement('a', props);
  if (as === 'div') return React.createElement('div', props);
  return React.createElement('button', props);
};

type ButtonProps = {
  $nowrap?: boolean;
  $stretch?: boolean;
  $rounded?: boolean;
  $size?: 'small' | 'smaller' | 'tiny' | 'large' | 'inline';
  $inline?: boolean;
  $outline?: boolean;
  $hideMobile?: boolean;
};

export const Button = styled(ButtonAs)<ButtonProps>`
  display: ${(props) => (props.$stretch ? 'flex' : 'inline-flex')};
  align-items: center;
  justify-content: center;
  gap: ${(props) => buttonGap(props)};
  width: ${(props) => (props.$stretch ? '100%' : 'auto')};
  height: ${(props) => (props.$size == 'inline' ? '38px' : 'auto')};
  min-height: ${(props) => (props.$inline ? '38px' : 'auto')};
  border: 1px solid transparent;
  border-radius: ${(props) => (props.$rounded ? '500px' : '5px')};
  white-space: ${(props) => (props.$nowrap ? 'nowrap' : 'inherit')};
  cursor: pointer;
  box-sizing: border-box;

  color: ${colors.primary.white};

  background: ${colors.primary.gray};

  font-size: ${(props) => buttonFontSize(props)};
  padding: ${(props) => buttonPadding(props)};

  &:disabled,
  &.disabled {
    cursor: default;
    filter: grayscale(60%);
    opacity: 0.5;
    &:hover {
      box-shadow: none;
    }
  }

  & > svg {
    height: ${(props) => iconSize(props)};
    width: ${(props) => iconSize(props)};
    &:last-child:not(:first-child) {
      margin-right: -5px;
    }
    &:first-child:not(:last-child) {
      margin-left: -5px;
    }
  }

  &:hover {
    background: ${getShades(colors.primary.gray)[400]};
    ${buttonHoverShadow}
  }
  @media (max-width: ${breakPoints.mobilePortrait}) {
    display: ${(props) => (props.$hideMobile ? 'none' : 'inherit')};
  }
  transition: background 0.1s ease-in-out;
`;

export const BasicButton = styled(Button)`
  /* HACK:!important is needed to override the default styles, it shouldnt be, but it is in certain cases */
  color: ${colors.primary.gray} !important;
  background: ${colors.secondary.lightGray2} !important;
  border-color: ${colors.secondary.lightGray3} !important;
  border-width: 1px !important;

  &:hover {
    background: ${getShades(colors.secondary.lightGray2)[300]} !important;
  }

  &:disabled,
  &.disabled {
    color: ${colors.secondary.darkGray1} !important;
    background: ${colors.secondary.lightGray2} !important;
    border-color: ${colors.secondary.lightGray2} !important;
  }
  &.danger {
    color: ${colors.primary.white} !important;
    background: ${colors.semantic.negative.dark} !important;
    border-color: ${colors.semantic.negative.dark} !important;
  }
  &.success {
    color: ${colors.primary.white} !important;
    background: ${colors.semantic.positive.primary} !important;
    border-color: ${colors.semantic.positive.primary} !important;
  }
`;

const successColors = {
  strong: getShades(colors.semantic.positive.primary)[600],
  normal: colors.semantic.positive.primary,
  pale: getShades(colors.semantic.positive.primary)[50],
};

export const SuccessButton = styled(Button)`
  /* HACK:!important is needed to override the default styles, it shouldnt be, but it is in certain cases */
  color: ${({ $outline }) => ($outline ? successColors.strong : colors.primary.white)} !important;
  background: ${({ $outline }) => ($outline ? colors.primary.white : successColors.normal)} !important;
  border-color: ${successColors.normal} !important;
  border-width: 1px !important;

  &:hover {
    background: ${({ $outline }) => ($outline ? successColors.pale : successColors.strong)} !important;
  }
`;

const dangerColors = {
  strong: getShades(colors.semantic.negative.primary)[700],
  normal: getShades(colors.semantic.negative.primary)[600],
  pale: getShades(colors.semantic.negative.primary)[50],
};

export const DangerButton = styled(Button)<{ $outline?: boolean }>`
  /* HACK:!important is needed to override the default styles, it shouldnt be, but it is in certain cases */
  color: ${({ $outline }) => ($outline ? dangerColors.strong : colors.primary.white)} !important;
  background: ${({ $outline }) => ($outline ? colors.primary.white : dangerColors.normal)} !important;
  border-color: ${dangerColors.normal} !important;
  border-width: 1px !important;

  &:hover {
    background: ${({ $outline }) => ($outline ? dangerColors.pale : dangerColors.strong)} !important;
  }
`;

const primaryButtonCss = css`
  color: white;
  background: ${primaryGradient};
  &:hover {
    background: ${primaryGradientHover};
  }
`;

const primaryOutlineButtonCss = css`
  position: relative;
  color: #6e40c9;
  z-index: 1;
  background: ${primaryGradient};
  &:hover {
    background: ${primaryGradientHover};
    &::before {
      background-color: ${toRgba(colors.primary.white, 0.9)};
    }
  }
  &::before {
    content: '';
    border-radius: 4px;
    position: absolute;
    inset: 1px;
    z-index: -1;
    background-color: white;
  }
`;

export const PrimaryButton = styled(Button)<{ $outline?: boolean }>`
  ${({ $outline }) => ($outline ? primaryOutlineButtonCss : primaryButtonCss)}
`;

export const SecondaryButton = styled(Button)`
  color: ${colors.primary.gray};
  background: transparent;
  border-color: ${colors.secondary.lightGray3};
  border-width: 1px;
  &:hover {
    box-shadow: none;
    background: ${toRgba(colors.secondary.lightGray1, 0.5)};
  }
  &:disabled,
  &.disabled {
    color: ${colors.semantic.disabled};
    background: transparent;
    border-color: ${colors.secondary.lightGray2};
  }
  &.danger {
    color: ${colors.semantic.negative.dark};
    background: transparent;
    border-color: ${colors.semantic.negative.dark};
  }
  &.success {
    color: ${colors.semantic.positive.dark};
    background: transparent;
    border-color: ${colors.semantic.positive.dark};
  }
  &.strong {
    background: transparent;
    border-color: ${colors.secondary.mediumGray};
  }
`;

export const TextButton = styled(Button)`
  color: ${colors.primary.gray};
  background: transparent;
  border-color: transparent;
  padding: 0;
  &.danger {
    color: ${colors.semantic.negative.dark};
    background: transparent;
  }
  &:hover {
    box-shadow: none;
    background: transparent;
    color: ${colors.semantic.link};
  }
  &:disabled,
  &.disabled {
    color: ${colors.semantic.disabled};
    background: transparent;
    border-color: transparent;
  }
`;

export const CtaButton = styled(Button)`
  color: ${colors.primary.white};
  background: ${colors.secondary.blue2};
  border-color: ${colors.secondary.blue2};
  border-width: 1px;
  &:hover {
    background: ${getShades(colors.secondary.blue3)[400]};
  }

  &:disabled,
  &.disabled {
    color: ${colors.primary.black};
    background: ${colors.secondary.blue1};
    border-color: ${colors.secondary.blue1};
  }

  &.alternate {
    color: ${colors.primary.white};
    background: ${colors.secondary.blue3};
    border-color: ${colors.secondary.blue3};
    &:hover {
      background: ${getShades(colors.secondary.blue3)[400]};
    }
  }

  &.selected {
    color: ${colors.primary.black};
    background: ${colors.secondary.blue1};
    border-color: ${colors.secondary.blue1};
  }
`;

export const MagicButton = styled(Button)`
  color: #6e40c9;
  background: ${colors.primary.white};

  border-color: transparent;

  /* padding: 0; */
  &:hover {
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1);
    background: ${colors.primary.white};
  }
  &:disabled,
  &.disabled {
    /* color: ${colors.semantic.disabled}; */
    background: transparent;
    border-color: ${colors.semantic.disabled};
  }
`;

export const MagicButtonWrapper = styled.div<{ $glow?: boolean; $borderWidth?: number }>`
  --border-angle: 0turn;

  display: inline-block;

  /* height: 100%; */
  position: relative;
  width: auto;

  & > * {
    position: relative;
    border: none;
  }

  &:not(.on) {
    /* z-index: 1; */
  }

  & {
    border-radius: ${({ $borderWidth }) => ($borderWidth || 2) / 2 + 5}px;
    z-index: 0;

    & > button {
      border-radius: 5px;
      border: none;
      border-color: lime;
    }

    --clr-1: #f87a7a;
    --clr-2: #f9947d;
    --clr-3: #f57ab1;
    --clr-4: #e07de8;
    --clr-5: #dbb2ed;
    --clr-6: #efb4c9;

    &::before,
    &::after {
      content: '';
      position: absolute;
      inset: -${({ $borderWidth }) => $borderWidth || 2}px;
      z-index: -1;

      background: conic-gradient(
        from var(--border-angle),
        var(--clr-1),
        var(--clr-2),
        var(--clr-3),
        var(--clr-4),
        var(--clr-5),
        var(--clr-6),
        var(--clr-1)
      );
      border-radius: inherit;
      background-position: center center;
      background-repeat: no-repeat;
      animation: bg-spin 2s linear infinite;
    }

    &:after {
      filter: ${(props) => (props.$glow ? 'blur(0.5rem)' : 'none')};
      opacity: 0.75;
    }

    @keyframes bg-spin {
      to {
        --border-angle: 1turn;
      }
    }
    &:hover {
      animation-play-state: paused;
      &:after {
        /* filter: ${(props) => (props.$glow ? 'blur(1rem)' : 'none')}; */
        opacity: 1;
      }
    }
    @property --border-angle {
      syntax: '<angle>';
      inherits: true;
      initial-value: 0turn;
    }
  }
`;
