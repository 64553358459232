import styled from 'styled-components';
import { colors, toRgba } from '@/styles';
import * as TooltipPrimitive from '@radix-ui/react-tooltip';

export const TooltipArrow = styled(TooltipPrimitive.Arrow)<{ $dark?: boolean }>`
  fill: ${({ $dark }) => ($dark ? colors.secondary.darkGray1 : colors.secondary.lightGray2)};
  margin-top: -1.2px;
  stroke: ${colors.secondary.mediumGray};
  stroke-width: 1px;
  /* Hide the border which overlaps the content above the arrow. */
  clip-path: inset(1px -10px -10px -10px);
`;

export const TooltipContent = styled(TooltipPrimitive.Content)<{ $small?: boolean; $dark?: boolean }>`
  border-radius: 4px;
  padding: ${({ $small }) => ($small ? '7px 8px' : '10px 15px')};
  line-height: 1.2;
  border: 0.5px solid ${colors.secondary.mediumGray};
  background-color: ${({ $dark }) => ($dark ? colors.secondary.darkGray1 : colors.secondary.lightGray2)};
  color: ${({ $dark }) => ($dark ? colors.primary.white : colors.primary.black)};
  box-shadow: ${toRgba(colors.primary.black, 0.1)} 3px 5px 12px 0px;
  font-size: ${({ $small }) => ($small ? '12px' : '14px')};
  user-select: none;
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;
  z-index: 1000;

  &[data-state='delayed-open'][data-side='top'] {
    animation-name: slideDownAndFade;
  }
  &[data-state='delayed-open'][data-side='right'] {
    animation-name: slideLeftAndFade;
  }
  &[data-state='delayed-open'][data-side='bottom'] {
    animation-name: slideUpAndFade;
  }
  &[data-state='delayed-open'][data-side='left'] {
    animation-name: slideRightAndFade;
  }

  @keyframes slideUpAndFade {
    from {
      opacity: 0;
      transform: translateY(2px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes slideRightAndFade {
    from {
      opacity: 0;
      transform: translateX(-2px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @keyframes slideDownAndFade {
    from {
      opacity: 0;
      transform: translateY(-2px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes slideLeftAndFade {
    from {
      opacity: 0;
      transform: translateX(2px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
`;
