'use client';

import * as React from 'react';
import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import * as Styled from './styled';

type TooltipProviderProps = TooltipPrimitive.TooltipProviderProps;

const TooltipProvider = ({ children, delayDuration = 100, ...props }: TooltipProviderProps) => (
  <TooltipPrimitive.Provider {...props} delayDuration={delayDuration}>
    {children}
  </TooltipPrimitive.Provider>
);

const Tooltip = TooltipPrimitive.Root;

const TooltipTrigger = TooltipPrimitive.Trigger;

const TooltipContent = React.forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content> & { small?: boolean; dark?: boolean }
>(({ className, sideOffset = 4, children, small, dark, ...props }, ref) => {
  const customBackground = props.style?.background || props.style?.backgroundColor;
  const customArrowStyle = customBackground ? { fill: customBackground as string } : {};

  return (
    <TooltipPrimitive.Portal>
      <Styled.TooltipContent
        ref={ref}
        sideOffset={sideOffset}
        {...props}
        $small={small}
        $dark={dark}
        collisionPadding={10}
      >
        <Styled.TooltipArrow width={10} height={8} style={customArrowStyle} $dark={dark} />
        {children}
      </Styled.TooltipContent>
    </TooltipPrimitive.Portal>
  );
});
TooltipContent.displayName = TooltipPrimitive.Content.displayName;

const SimpleTooltip = ({
  children,
  content,
  style,
  open,
  disabled,
  defaultOpen,
  onOpenChange,
  side,
  small,
  dark,
}: {
  children: React.ReactNode;
  content: React.ReactNode;
  style?: React.CSSProperties;
  open?: boolean;
  disabled?: boolean;
  defaultOpen?: boolean;
  onOpenChange?: (open: boolean) => void;
  side?: TooltipPrimitive.TooltipContentProps['side'];
  small?: boolean;
  dark?: boolean;
}) => {
  return (
    <Tooltip open={disabled ? false : open} defaultOpen={defaultOpen} onOpenChange={onOpenChange}>
      <TooltipTrigger asChild>
        <span style={{ display: 'inline-flex' }}>{children}</span>
      </TooltipTrigger>
      <TooltipContent style={style} side={side} small={small} dark={dark}>
        {content}
      </TooltipContent>
    </Tooltip>
  );
};

export {
  Tooltip as Root,
  TooltipTrigger as Trigger,
  TooltipContent as Content,
  TooltipProvider as Provider,
  SimpleTooltip as Simple,
};

export type TooltipContentProps = TooltipPrimitive.TooltipContentProps;
