import * as Styled from './styled';

interface StackProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
  gap?: number;
  alignItems?: React.CSSProperties['alignItems'];
  justifyContent?: React.CSSProperties['justifyContent'];
  noStretch?: boolean;
  theme?: any;
  style?: React.CSSProperties;
}

const Stack: React.FC<StackProps> = ({
  children,
  gap = 50,
  style,
  alignItems,
  justifyContent,
  theme = {},
  noStretch,
  ...rest
}) => {
  return (
    <Styled.Stack
      $noStretch={noStretch}
      {...rest}
      style={{ ...style, gap: gap, alignItems: alignItems, justifyContent: justifyContent }}
    >
      {children}
    </Styled.Stack>
  );
};

const Centered: React.FC<StackProps> = ({
  children,
  gap = 50,
  theme = {},
  style,
  alignItems,
  justifyContent,
  ...rest
}) => {
  return (
    <Styled.CenteredStack
      {...rest}
      style={{ ...style, gap: gap, alignItems: alignItems, justifyContent: justifyContent }}
    >
      {children}
    </Styled.CenteredStack>
  );
};

const Left: React.FC<StackProps> = ({ children, gap = 50, theme = {}, style, alignItems, justifyContent, ...rest }) => {
  return (
    <Styled.LeftStack {...rest} style={{ ...style, gap: gap, alignItems: alignItems, justifyContent: justifyContent }}>
      {children}
    </Styled.LeftStack>
  );
};

const Right: React.FC<StackProps> = ({
  children,
  gap = 50,
  theme = {},
  style,
  alignItems,
  justifyContent,
  ...rest
}) => {
  return (
    <Styled.RightStack {...rest} style={{ ...style, gap: gap, alignItems: alignItems, justifyContent: justifyContent }}>
      {children}
    </Styled.RightStack>
  );
};

let ComposedStack = Object.assign(Stack, {
  Centered: Centered,
  Right: Right,
  Left: Left,
});

export default ComposedStack;
